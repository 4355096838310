import { useEffect, useContext } from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { captureException } from "@sentry/react";
import { wmaxxUrl } from "utils/config";
import { decode } from "utils/utils";
import { Context } from "pageFiles/App/Context";

import { useApolloClient } from "@apollo/client";
import { GET_CMS_USER_ID } from "utils/gqlQueries";

/* This page is just for wmaxx (php) login & redirect */
const Login = ({ redirect }) => {
  const router = useRouter();
  const context = useContext(Context);

  const client = useApolloClient();

  useEffect(() => {
    async function pingAndRedirect() {
      // The purpose of this call is to make Redis aware that the user is active
      // User should already be authenticated when this page is reached
      const userId = await client
        .query({
          query: GET_CMS_USER_ID,
          fetchPolicy: "network-only",
        })
        .then(({ data: { getCmsUserId: id } }) => id)
        .catch((err) => {
          console.error({
            getCmsUserId: typeof err === "object" ? err.message : err,
          });
          captureException("getCmsUserId", err);
        });

      if (userId && context.user.preferredMFA === "SOFTWARE_TOKEN_MFA") {
        const url = redirect ? wmaxxUrl + decode(redirect) : "/orders";
        console.log("login redirecting to " + url);
        window.location.replace(url);
      } else {
        console.log("missing userId or preferredMFA");
      }
    }

    pingAndRedirect();
  }, [router, client, redirect, context.user.preferredMFA]);

  return null;

  // return (
  //   <div className="h-full flex items-center justify-center text-blue-600">
  //     <a href="/orders">Go to Orders</a>
  //   </div>
  // );
};

Login.getInitialProps = ({ query: { wm } }) => {
  return {
    redirect: wm?.replace("https://wmaxx.watchmaxx.com/cms/admin.php", ""),
  };
};

Login.propTypes = { redirect: PropTypes.string };

export default Login;
